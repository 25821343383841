
const PricingSection = () => import("@/components/main/pricing/PricingSection");
const RedSection = () => import("@/components/main/core/RedSection");
const Steps = () => import("@/components/main/steps/Steps");
const BoxSection = () => import("@/components/main/BoxSection");
const TabsSection = () => import("@/components/main/tabs/TabsSection");
const GreySection = () => import("@/components/main/core/GreySection");
const BoxPhoto = () => import("@/components/main/core/BoxPhoto");
const CheckBox = () => import("@/components/main/core/CheckBox");

const faqMainPage = () => import("@/components/main/faq/faqMainPage");


import judi from "@/assets/images/main/main-page-comments/judi-ava.png"
import jessica from "@/assets/images/main/main-page-comments/jessica-ava.png"
import rique from "@/assets/images/main/main-page-comments/rique-ava.png"
import gwen from "@/assets/images/main/main-page-comments/gwen-ava.png"
import patricia from "@/assets/images/main/main-page-comments/patricia-ava.png"

import data from "@/mixins/data"
import isMob from "@/mixins/isMob";

import "@/plugins/carousel-plugin"


export default {
  name: 'index',
  layout: 'clientLayout',

  head() {
    const title = 'Best Website to Find Out if Spouse is Cheating - Infidelity Website & App | iFindCheaters';
    const description = 'Check out to find your cheating wife, husband, girlfriend, or boyfriend online on dating apps and social media. Use our advanced cheater finder tool to catch cheaters online in a few clicks now!';

    return {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'cheating website, best apps for cheating, cheaters app free, catch cheaters, best infidelity sites, best infidelity websites, cheater finder, find cheating wives, best site to catch a cheater'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title
        }
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: "https://ifindcheaters.com/"
        }
      ],
    };
  },

  data() {
    return {
      comments: [
        {
          img: judi,
          comment: `"I was devastated when I saw he had active accounts but we talked it out and our relationship is better today. it's the best site to catch a cheater."`,
          by: 'by Judi, USA <br> Aug 08, 2018'
        },

        {
          img: jessica,
          comment: `"Glad I used this site, got the info I needed. best website to find cheaters."`,
          by: 'by Jessica, Canada <br> Oct 14, 2020'
        },

        {
          img: rique,
          comment: `"Feels good to know it’s real. Now I can open up and be myself. The easiest way find cheating wives."`,
          by: 'by Rique, Great Britain <br> May 05, 2021'
        },
        {
          img: gwen,
          comment: `"After my divorce I was afraid to start dating again but a friend told me about ifindcheaters as cheater finder. I was skeptical, but when I saw how easy it was to see who I was really meeting I felt a lot more confidant."`,
          by: 'by Gwen, Germany <br> Jul 05, 2023'
        },
        {
          img: patricia,
          comment: `"I was so happy, but my parents kept nagging me not to commit till I was sure. ifindcheaters is the best website to find out if spouse is cheating and proved I was right!"`,
          by: 'by Patricia, Italy <br> Feb 24, 2022'
        },
      ],

      isAccepted: false,
      displaySearches: "325664",
      displayFound: "96332",
    }
  },
  computed: {
    random() {
      return Math.floor(Math.random() * this.comments.length);
    }
  },

  mixins: [data, isMob],


  components: {
    BoxSection,
    Steps,
    TabsSection,
    GreySection,
    RedSection,
    PricingSection,
    BoxPhoto,
    CheckBox,
    faqMainPage,
  },

  methods: {
    accept() {
      this.isAccepted = !this.isAccepted
    },
    outNumSearches(num, step) {
      var time = 100000;
      var n = 325664;
      var t = Math.round(time / (num / step));
      let interval = setInterval(() => {
        n = n + step;
        this.displaySearches = n;
        if (n >= num) {
          clearInterval(interval);
        }
      }, t);
    },

    outNumFound(num, step) {
      var time = 70000;
      var n = 96332;
      var t = Math.round(time / (num / step));
      let interval = setInterval(() => {
        n += step;
        this.displayFound = n;
        if (n >= num) {
          clearInterval(interval);
        }
      }, t);
    },
  },


  mounted() {
    this.outNumSearches(1327296, 2135);
    this.outNumFound(398189, 862);
    this.myWindow = window;
    this.isAccepted = false;
  },
}
